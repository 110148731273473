.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  color: #F74A29;
  position: relative;
  margin: 0 auto;
}

.App-header {
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #F74A29;
}

